<template>
  <h1>Calculators</h1>

  <hr>

  <VoltageDivider/>

</template>

<script>
import VoltageDivider from "@/components/calculators/VoltageDivider";

export default {
  name: "CalculatorTab",
  components: {VoltageDivider}
}
</script>

<style scoped>

</style>