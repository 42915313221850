<template>
  <img src="img/me-and-meg.jpg" width="400" alt="My wife and I at Devil's Tower"/>

  <p>
    My name is Brendan Kristiansen, I live and work in Sheridan, Wyoming, and I love maps, embedded systems, and data
    collection. I currently work at <a href="https://agterra.com">AgTerra Technologies</a>, where I am the primary
    party responsible for research and development on the company's new line of cellular-connected data loggers, the
    <a href="https://www.agterra.com/products/actionmapper/">ActionMapper</a>. While leading the ActionMapper's
    initial development, I was in charge of chip selection, firmware development, PCB design, and mechanical design.
    I continue to work on replacing obsolete components as necessary and planning major future improvements.
  </p>

  <p>
    I also wrote the application that processes ActionMapper data that is uploaded to our servers, the Android code
    to communicate with an ActionMapper via Bluetooth, and several small improvements to the websites that visualize
    geospatial data. The primary technologies I use at AgTerra are Android (Kotlin + Java), .NET (mostly MVC), Vue.JS,
    KiCAD, QGIS, and Autodesk Fusion.
  </p>

  <p>
    I previously worked for <a href="https://www.nwbsensors.com"> NWB Sensors </a> in Bozeman, Montana.
    My primary work at NWB was on the data collection system that is a part of their agricultural
    <a href="https://www.nwbsensors.com/field-mapping">field mapping system</a>. The devices I worked with collected
    image data from camera systems mounted on combines. Using machine vision, the data was analyzed to automatically
    detect and map anomalies in fields, such as weeds and animal dens. I wrote the code
    that facilitates all of the data collection and processing for this project, and was the primary maintainer of
    a QGIS fork geared towards the presentation of data from this project.
  </p>

  <p>
    I also spent considerable time on NWB's <a href="https://www.nwbsensors.com/snowpack-monitoring"> snowpack monitoring
    system </a>, where I wrote the initial code to add L2 GPS support to NWB's prototype solver in Matlab.
    I also am the original author of most of the drivers to allow the firmware running in the snow project's embedded
    system to communicate with connected hardware.
  </p>

  <p>
    The technologies I used most at NWB were embedded Linux, Python, OpenCV and Tensorflow.
  </p>
  <br/>

  <h3> Education </h3>
  <h4> Montana State University - Bozeman, MT </h4>
  B.S. in Computer Science. Graduated May 2020.
  <br>Coursework:
  <ul>
    <li> CSCI 494 - Seminar: Computational Topology and Geometry</li>
    <li> CSCI 476 - Computer Security (C, Python, PHP, SQL)</li>
    <li> CSCI 466 - Networks (Python, Wireshark)</li>
    <li> CSCI 460 - Operating Systems (C)</li>
    <li> CSCI 451 - Computational Biology (Python)</li>
    <li> CSCI 442 - Computer Vision (Java, Python, OpenCV)</li>
    <li> CSCI 441 - Computer Graphics (C++, OpenGL)</li>
    <li> CSCI 440 - Database Systems (SQL)</li>
    <li> CSCI 361 - Computer Architecture (MIPS Assembly)</li>
    <li> CSCI 351 - Systems Administration</li>
    <li> CSCI 338 - Computer Science Theory</li>
    <li> CSCI 305 - Concepts of Programming Languages (Go, Kotlin, Dart)</li>
    <li> ESOF 322 - Software Engineering (Java)</li>
    <li> EGEN 310 - Multidisciplinary Engineering Design (Python)</li>
    <li> M 273 - Multivariable Calculus</li>
    <li> M 221 - Intro to Linear Algebra</li>
  </ul>
  Advisor:
  <a href="http://www.cs.montana.edu/directory/1957469/david-millman" target="_blank">
    Dr. David Millman</a>

  <h3> Academic Research </h3>

  <h4> Fortran-Silo </h4>
  <p>
    Fortran-Silo is a library designed to extend the Fortran functionality of
    Lawrence Livermore National Laboratory's Silo library. Silo is a library
    that streamlines reading and writing geometric mesh data in C using HDF5,
    but has the ability to write meshes out from Fortran. Currently, there is no
    option to read Silo files in a Fortran program. My library contains a set of
    functions to accompany Silo's native functionality with a similar interface
    that will allow the user the ability to read a select set of Silo data types
    from silo files.
    <br><a href="https://bitbucket.org/bek3/fortran-silo" target="_blank">Project Repository</a>
  </p>

  <h4> NeuroCAVE </h4>
  <p>
    The NeuroCAVE Collaborative is a team of artists, music technologists,
    and computer scientists whose goal is to create an interactive environment
    where a participant's brain activity is measured and used to change the
    environment around them. My role in the project was to automate the the
    process of pairing a set of computers each to a bluetooth headset using
    Google Firebase to host the configuration. In 2017, the Cave spent 4 months
    on display at the Holter Museum of Art in Helena, MT. and is currently on
    display in MSU's Norm Asbjornson Hall.
    <br><a href="https://montana.edu/cave" target="_blank">Project Website</a>
    <br><a href="https://github.com/dlm/msu-cave" target="_blank">Project Repository</a>
    <br><a href="https://www.msuexponent.com/culture/rock-on-with-cave-exhibit/
			article_e15e9cfa-2b3a-11e9-9d23-6feaad44f40b.html" target="_blank">
    MSU Exponent Article</a>
  </p>

  <h4> MSU Storytelling with Alice </h4>
  <p>
    The storytelling team is developing and researching storytelling as a culturally
    responsive way to engage American Indian and rural Montana middle school
    students in learning computer science and computing skills. Instead of
    creating a new curriculum (and new standards for teachers to meet), the
    project will infuse computer science across the curriculum, which will help
    students understand that computing skills are relevant across disciplines
    and are important for a wide variety of professions in the work-force. These
    lessons will use Alice, an object-based educational programming environment
    that has already proven to be successful in engaging and retaining diverse
    and under-served groups in computer science.
    <br><a href="https://montana.edu/storytelling" target="_blank">Project Website</a>
    <br><a href="http://www.montana.edu/news/17106/msu-researchers-developing-
		storytelling-as-way-to-help-teach-computing-skills?fbclid=
		IwAR0OsEcoBVApfoidvil6e8P7Im3ytcCC9Rdry5VylRBVOuiPSY_9jxbDE-M" target="_blank">
    MSU News Article</a>
  </p>

  <h3> Other Things I Do </h3>

  <h4> Music </h4>

  <p>
    When I am not working on a side project, I am usually playing music. During the school year, I enjoy playing
    saxophone in the <a href="https://www.sheridan.edu/academics/programs-a-z/music/">Sheridan College Symphony Band</a>
    with my wife, who plays flute. During the summer, we play in a different community band that plays three free
    outdoor concerts every year in Sheridan's Kendrick Park. I also play bass guitar in a classic rock cover band that
    plays in various bars and street festivals across our corner of Wyoming and nearby parts of Montana. I also spend
    many weekends and evenings with friends jamming on any instruments we can find.
  </p>

  <br />
  <h4> Photography </h4>

  <p>
    For most of my college career, I worked at the Exponent, Montana State
    University's student newspaper. I started working as a staff photographer,
    then moved up to art director and eventually managing editor. I have had the
    honor of photographing sporting events, Astronaut Scott Kelly,
    <a href="https://www.msuexponent.com/photos-norah-jones-at-the-brick-
			breeden-fieldhouse/collection_932bff56-adca-11e9-ab54-afc7b80445c6.
			html" target="_blank">
      Norah Jones </a>former Attourney General Jeff Sessions, former Illinois
    governor Bruce Rauner and even
    <a href="https://www.msuexponent.com/president-trump-rally-belgrade-mont/
			collection_adaa7f68-e30b-11e8-aeda-bf1fad966f6c.html" target="_blank">
      former President Donald Trump.</a>
  </p>

</template>

<script>
export default {
  name: "HomeTab"
}
</script>

<style scoped>
img {
  float: left;
  margin: 16px;
}
</style>