<template>
  <h1> Python NMEA Parser </h1>
  <br>

  NMEA Parser is a Python library designed to parse NMEA streams from a GNSS receiver on a serial port in real time.
  Data is returned in easy to examine structures called "data frames" that contain latitude, longitude, speed, track,
  fix quality, and other useful attributes.

  <br><br>

  Documentation for NMEA Parser is available to the left. The project is currently available on Gitlab and PyPI.

  <h2>Links</h2>
  <ul>
    <li><a href="/docs/manuals/nmea-parser-0.5.0.pdf"> Most Recent Documentation </a></li>
    <li><a href="https://gitlab.com/bek3/nmea_parser">Project Repository</a></li>
    <li><a href="https://pypi.org/project/nmea-parser/"> Python Package Index </a></li>
  </ul>
</template>

<script>
export default {
  name: "NmeaParserTab"
}
</script>

<style scoped>

</style>