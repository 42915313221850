<template>
  <h2>Voltage Divider Calculator</h2>

  <p>
    This calculator is designed to provide resistor values for a voltage divider given a desired input and output
    voltage using only common resistor values. Because this calculator only uses common resistor values, it will not
    provide an exact divider, but will simply pick the pair of common resistor values that are closest to the desired
    voltage division.
  </p>

  Calculator Mode: <br>
  <input type="radio" id="noVals" name="calcMode" value="0" @click="switchModes(0)" checked> No Resistor Values Given <br>
  <input type="radio" id="1Val" name="calcMode" value="1" @click="switchModes(1)"> Only R1 Given <br>
  <input type="radio" id="2Val" name="calcMode" value="1" @click="switchModes(2)"> Only R2 Given <br>

  <div class="alert alert-warning" role="alert" v-if="commonValWarning">
    Uncommon resistor value used in manual entry.
  </div>

  <br>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text field-label">Input</span>
    </div>
    <input type="number" class="form-control" v-model="v_in" >
    <div class="input-group-append">
      <span class="input-group-text unit-label">Volts</span>
    </div>
  </div>

  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text field-label">Ideal Output</span>
    </div>
    <input type="number" class="form-control" v-model="v_out_ideal">
    <div class="input-group-append">
      <span class="input-group-text unit-label">Volts</span>
    </div>
  </div>

  <hr>

  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text field-label">R1 Value</span>
    </div>
    <input type="number" class="form-control" v-model="r_1" :disabled="!mode1">
    <div class="input-group-append">
      <span class="input-group-text unit-label">Ohms</span>
    </div>
  </div>

  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text field-label">R2 Value</span>
    </div>
    <input type="number" class="form-control" v-model="r_2" :disabled="!mode2">
    <div class="input-group-append">
      <span class="input-group-text unit-label">Ohms</span>
    </div>
  </div>

  <hr>

  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text field-label">Actual Output</span>
    </div>
    <input type="number" class="form-control" v-model="v_out_act" disabled>
    <div class="input-group-append">
      <span class="input-group-text unit-label">Volts</span>
    </div>
  </div>


  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text field-label">Percent Error</span>
    </div>
    <input type="number" class="form-control" disabled v-model="pctErr">
  </div>

  <br>
  <button @click="solve()" class="btn btn-primary">Calculate!</button>

</template>

<script>

import _ from 'lodash';

export default {
  name: "VoltageDivider",

  data (){
    return {
      v_in: 0,
      v_out_ideal: 0,
      v_out_act: 0,
      r_1: 0,
      r_2: 0,

      pctErr: 0,

      mode0: true,
      mode1: false,
      mode2: false,

      commonValues: [1, 2, 3, 10, 12, 15, 18, 22, 27,
      33, 39, 47, 56, 68, 82, 100, 120, 150, 180, 220,
      270, 330, 390, 470, 560, 680, 820, 1000, 1200, 1500,
      1800, 2200, 2700, 3300, 3900, 4700, 5600, 6800,
      8200, 10000, 12000, 15000, 18000, 22000, 27000, 33000,
      39000, 47000, 56000, 68000, 82000, 100000, 120000,
      150000, 180000, 220000, 270000, 330000, 390000,
      470000, 560000, 680000, 820000],

      commonValWarning: false
    }
  },

  methods: {

    switchModes: function(modeNum){
      this.mode0 = this.mode1 = this.mode2 = false;
      if (modeNum === 0) this.mode0 = true;
      else if (modeNum === 1) this.mode1 = true;
      else if (modeNum === 2) this.mode2 = true;

      this.r_1 = 0;
      this.r_2 = 0;
    },

    solve: function(){
      console.log(this.mode0, this.mode1, this.mode2);
      if (this.mode0){

        var smallestPctDiff = -1
        var smallestI = -1;

        for (var i = 0; i < this.commonValues.length; i++){
          this.r_1 = this.commonValues[i];
          var r2temp = this.solveDividerForR2();
          this.r_2 = this.getNearestCommonVal(r2temp);
          var vAct = this.solveDividerForVOut();
          var pctDiff = this.calculatePercentDifference(this.v_out_ideal, vAct);
          if (pctDiff < smallestPctDiff || smallestPctDiff === -1){
            smallestI = i;
            smallestPctDiff = pctDiff;
          }
        }

        this.r_1 = this.commonValues[smallestI];
        var r2 = this.solveDividerForR2();
        this.r_2 = this.getNearestCommonVal(r2);
        this.v_out_act = this.solveDividerForVOut();
        this.pctErr = this.calculatePercentDifference(this.v_out_act, this.v_out_ideal);

      }else if (this.mode1){
        this.commonValWarning = !this.isCommonValue(this.r_1)
        var r2_ideal = this.solveDividerForR2();
        console.log("Ideal R2 value: ", r2_ideal)
        this.r_2 = this.getNearestCommonVal(r2_ideal);
        console.log("Closest R2 value: ", this.r_2);
      }else if (this.mode2){
        this.commonValWarning = !this.isCommonValue(this.r_2)
        var r1_ideal = this.solveDividerForR1();
        this.r_1 = this.getNearestCommonVal(r1_ideal);
      }else{
        console.error("Invalid state. Some mode needs to be active")
      }
      this.v_out_act = this.solveDividerForVOut();
      this.pctErr = this.calculatePercentDifference(this.v_out_ideal, this.v_out_act);
    },

    isCommonValue: function (val) {
      return _.includes(this.commonValues, val);
    },

    solveDividerForR1: function(){
      return (this.v_in * this.r_2 - this.v_out_ideal * this.r_2) / this.v_out_ideal;
    },

    solveDividerForR2: function(){
      return (this.v_out_ideal * this.r_1) / (this.v_in - this.v_out_ideal)
    },

    solveDividerForVOut: function(){
      return (this.v_in * this.r_2) / (this.r_1 + this.r_2);
    },

    getNearestCommonVal: function(target){

      var smallestIdx = -1;
      var smallestDiff = -1;
      for (var i = 0; i < this.commonValues.length; i++){
        var thisValue = this.commonValues[i];
        var diff = Math.abs(target - thisValue);
        if (diff < smallestDiff || smallestIdx === -1){
          smallestIdx = i;
          smallestDiff = diff;
        }
      }

      return this.commonValues[smallestIdx];
    },

    inputsAreValid: function(){
      if (this.v_in < this.v_out_ideal) return false;
      if (this.r_2 < 0) return false;
      if (this.r_1 < 0) return false;

      return true;
    },

    calculatePercentDifference: function (v1, v2){
      return Math.abs((v1 - v2) / 2);
    }
  },
}
</script>

<style scoped>

.field-label{
  width: 8em;
}

.unit-label{
  width: 5em;
}

</style>