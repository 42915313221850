<template>

  <head>
    <meta http-equiv="content-type" content="text/html; charset=iso-8859-1"/>
  </head>

  <nav class="navbar navbar-expand-lg navbar-light bg-light sticky" id="navbar">
    <a class="navbar-brand" href="#" @click="activate_tab('home')" :class="{activeMain: active_tab === 'home'}">
      <span class="home-label">Brendan Kristiansen </span>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="docs/resume.pdf" target="_blank">Resume</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.linkedin.com/in/brendan-kristiansen/" target="_blank">Linkedin</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://gitlab.com/bek3" target="_blank">Gitlab</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-dark" href="https://www.wellknowntext.com"> WellKnownText </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-dark" href="#" @click="activate_tab('nmea')" id="nmeaNavItem" :class="{active: active_tab === 'nmea'}"> Python NMEA Parser </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-dark" href="#" @click="activate_tab('calc')" id="calcNavItem" :class="{active: active_tab === 'calc'}"> Useful Calculators </a>
        </li>
      </ul>
    </div>
  </nav>

  <body>
  <div class="content container">
    <div class="card border-0 shadow my-5">
      <div class="card-body p-5 lead">
        <NmeaParserTab v-if="active_tab === 'nmea'"/>
        <CalculatorTab v-if="active_tab === 'calc'"/>
        <HomeTab v-if="active_tab === 'home'"/>
      </div>
    </div>
  </div>
  </body>



</template>

<script>

import HomeTab from "@/components/HomeTab";
import NmeaParserTab from "@/components/NmeaParserTab";
import CalculatorTab from "@/components/CalculatorTab";
export default {
  name: 'App',
  components: {
    CalculatorTab,
    NmeaParserTab,
    HomeTab
  },

  data(){
    return {
      active_tab: "home"
    }
  },

  methods: {
    activate_tab: function(tabname){
      this.active_tab = tabname;

      switch (tabname) {
        case 'nmea':
      }
    }
  }
}
</script>

<style>
@import'~bootstrap/dist/css/bootstrap.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 100px;
}

header {
  background-color: white;
  border-bottom-color: darkslategrey;
}

footer {
  background-color: white;
  border-top-color: darkgray;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

#footer {

  width: 100%;
  height: 100px;
  background: blue;
}

.card{
  background: white;
}

body {
  margin-bottom: 60px;
  background-image: linear-gradient(to right, #363945, #00758F);
  no-repeat: center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

/*#navbar{*/
/*  padding: 12px;*/
/*}*/

/*.navbar-brand{*/
/*  margin-left: 12px;*/
/*}*/

nav {
  height: 40px;
}

.home-label{
  margin-left: 12px;
  margin-right: 12px;
}

.active{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color: white !important;
  background: #FA7A35;
}

.activeMain {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color: white !important;
  background: #FA7A35;
}

.content {
  text-align: left;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000 !important;
}

h3 {
  margin-top: 24px;
}

</style>
